.background {
  background-image: url(../assets/images/Backdrop.png);
  padding: 40px 0px 60px 0px;
  min-height: 100%;
  background-size: cover !important;
  background-position: center !important;

  .Login-form {
    max-width: 600px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 0 auto;
    margin-top: 50px;
    background-color: $white;

    @media screen and (max-width: 768px) {
      width: 90% !important;
      margin: 0 auto !important;

      .input-group label,
      .input-group input,
      .input-group .error-text {
        font-size: 14px;
        padding: 8px;
      }
    }

    .side-heading {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .sub-text {
      font-size: 16px;
      margin-bottom: 10px;

      padding: 0px 0px 20px 0px;
      color: #6c6c6c;
      font-weight: 400;

      span {
        color: blue;
      }
    }

    .input-group {
      margin-bottom: 20px;

      span {
        color: red;
      }

      label {
        display: block;
        font-size: 16px;
        margin-bottom: 5px;
      }

      input {
        position: relative;
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
      }

      .eye-icon {
        font-size: 18px;
        cursor: pointer;
        position: absolute;
        top: 42%;
        left: 95%;

        @media(max-width:768px) {
          top: 56%;
          left: 93% !important;
        }

        img {
          width: 18px;
          height: 18px;

        }
      }
    }

    .signin-btn {
      width: 98%;
      padding: 10px;
      background-color: $theme-bg;
      color: $white;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      cursor: pointer;
    }

    .sub-text:last-child {
      margin-top: 10px;
    }

    .social-buttons {
        display: flex;
        justify-content: center;
        align-items: center;


      button {
        // width: 100%;
        // padding: 10px;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        margin-bottom: 10px;
        cursor: pointer;
        background: white;
        border: 1px solid #333333;
        margin-bottom: 22px;
        color: #6c6c6c;
        font-weight: 600;

        img {
          width: 25px;
          margin-right: 10px;
        }
      }
    }
  }
}

/* Login.css */
.social-buttons{
  display: flex;
  justify-content: center;
  align-items: center;
  h5{
    margin-bottom: 0px;
  }
  .Social-btn-container-inner-icons {
  width: 40px !important;
  margin: 10px !important;
  &-facebook{
    background-color: white !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dadce0 !important;
    // margin-bottom: 0 !important;
    margin: 10px !important;
    img{
      margin-right:  0 !important;
    }
  }
  }
}
