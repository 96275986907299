.header {
  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .col {
      flex: 1;
      padding: 10px;
      text-align: center;
      &:nth-child(2) {
        flex: 2;
      }
    }
  }
  &-logo {
    display: flex;
    height: 5rem;
    img {
      width: 100%;
      mix-blend-mode: multiply;
      object-fit: contain;
    }
  }
  &-section-container {
    background-color: white;
  }
  &-icons {
    display: flex;
    &-link {
      color: black;
      text-decoration: none;
    }
  }
  &-dropdown-container {
    background: $theme-bg;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &-dropdown {
    text-decoration: none;
    padding: 5px 10px;
    text-decoration: none !important;
    a {
      text-decoration: none !important;
    }
    .dropdown-menu-none {
      display: none;
    }
  }
  &-dropdown-btn {
    color: $white;
    text-transform: capitalize;
  }
  &-link {
    color: $white;
    text-decoration: none;
    vertical-align: sub;
    text-transform: capitalize;
  }
  &-desktop-section {
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
  }
}

//mobile
.header-mobile {
  &-section {
    display: block;
    @media (min-width: 768px) {
      display: none;
    }
  }
  &-logo {
    display: block;
    img {
      width: 8rem;
      mix-blend-mode: multiply;
      object-fit: contain;
      @media (max-width:768px){
        width:7rem;
      }
    }
  }
  &-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 999;
    align-items: center;
    padding: 1.2rem 1rem;
    background-color: white;
    @media (max-width: 768px) {
      padding: 0.8rem 1rem;
    }
    .humburger {
      img {
        width: 30px;
        height: 30px;
      }
      background-color: $theme-bg;
      padding: 8px;
    }
  }
  &-icons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-wrapper {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 78px;
    left: -100%;
    bottom: 0;
    display: flex;
    padding: 30px 20px;
    transition: ease 1s;
    background: $theme-bg;
    z-index: 100;
    &.open {
      left: 0;
      top: 78px;
      transition: ease 1s;
    }
    .dropdown-menu#mobile-menu-dropdown {
      position: static !important;
      transform: none !important;
      background-color: transparent !important;
      color: white !important;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.584);
      border-radius: 0px;
      padding: 10px 20px;

      .dropdown-item {
        color: white;
        transition: 0.2s;
        &:hover {
          background-color: $theme-bg;
        }
      }
    }
    .dropdown.header-dropdown {
      transition: 0.2s;

      &:hover {
        background-color: rgb(19, 114, 159);
      }
    }
    .btn:first-child:active {
      border: none !important;
      background-color: transparent !important;
    }
  }

  &-links-container {
    display: block;
    text-transform: capitalize;
  }
}

// Global Search and Nav Icons Css
.global-search {
  .input-group-text {
    padding: 0.6rem 1.6rem;
    background-color: $theme-bg;
    color: white;
    border: 1px solid $theme-bg;
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    img {
      width: 20px;
      height: 20px;
      @media (max-width: 768px) {
        width: 15px;
        height: 15px;
      }
    }
  }
  input {
    &::placeholder {
      color: rgb(138, 138, 138);
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    border-top-left-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    padding: 0.6rem 1.6rem;
    border: 1px solid $theme-bg;
    @media (max-width: 768px) {
      border-top-left-radius: 18px !important;
      border-bottom-left-radius: 18px !important;
      padding: 0.4rem 1.2rem;
    }
  }
}

.header-icons-link {
  .account-hover {
    cursor: pointer;
  }
  // .dropdown-menu {
  //   transition: 0.5s;
  // }
  // .account-hover {
  //   .dropdown-menu {
  //     transition: 0.5s;
  //   }
  //   &:hover {
  //     .dropdown-menu {
  //       // display: block !important;
  //       transition: 0.5s;
  //     }
  //   }
  // }
  p {
    font-size: 14px;
    text-transform: capitalize;
    color: rgb(105, 105, 105);
    @media (max-width: 768px) {
      font-size: 11px;
      padding-right:5px;
    }
  }
  img {
    width: 1.7rem;
    height: 1.7rem;
    @media (max-width: 768px) {
      width: 1.3rem;
      height: 1.3rem;
    }
  }
  .dropdown-menu {
    span {
      color: rgb(75, 75, 75);
    }
    img {
      width: 1.5rem;
      height: 1.5rem;
      margin: 8px;
      @media (max-width: 768px) {
        width: 1.2rem;
        height: 1.2rem;
        margin: 3px;
      }
    }
    .dropdown-item {
      &:active {
        background-color: $theme-bg;
        span {
          color: white !important;
        }
      }
    }
  }
  .userEmail {
    color: rgb(54, 54, 54);
    font-size: 14px;
    text-transform: none;
  }
}

// Global Search and Nav Icons Css
.global-search {
  .input-group-text {
    padding: 0.6rem 1.6rem;
    background-color: $theme-bg;
    color: white;
    border: 1px solid $theme-bg;
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    img {
      width: 20px;
      height: 20px;
      @media (max-width: 768px) {
        width: 15px;
        height: 15px;
      }
    }
  }
  input {
    &::placeholder {
      color: rgb(138, 138, 138);
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    border-top-left-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    padding: 0.6rem 1.6rem;
    border: 1px solid $theme-bg;
    @media (max-width: 768px) {
      border-top-left-radius: 18px !important;
      border-bottom-left-radius: 18px !important;
      padding: 0.4rem 1.2rem;
    }
  }
  .search-Align {
    position: absolute;
    top: 46px;
    width: 323px;
    background: #f8f9fa;
    z-index: 999;
    padding: 8px 20px;
    line-height: 2.3;
    display: flex;
    flex-direction: column;
    display: none;
    &.show {
      display: block !important;
      text-align: left;
    }
    a {
      text-decoration: none;
      p {
        text-align: left;
        color: #000;
        font-size: 14px;
        margin-bottom: 0;
      }
      .item-in-category {
        font-size: 10px;
        font-weight: 600;
        color: $theme-bg;
      }
    }
    cursor: pointer;
  }
}
.LoginAccountPopup {
  .btn {
    background-color: $theme-bg;
    color: white;
    width: 100%;
    margin: auto;
    &:hover {
      background-color: $theme-bg;
      color: white;
    }
  }
}
