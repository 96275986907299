.AddAddress-popup{
    .cart-popup{
      max-height: 90vh;
        width: 800px !important;
        overflow-y: scroll;
        @media (max-width: 996px) {
          max-height: 80vh;
            width: 600px !important;
          }
          @media (max-width: 768px) {
            width: 450px !important;
            max-width: 100% !important;
          }
    }
}