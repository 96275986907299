.My-orders-wrapper{
    border: 1.5px solid rgb(212, 212, 212);
    margin: 20px auto;
    padding: 10px;
    border-radius: 10px;
        .Myorders-sub-head{
            font-size: 0.9rem;
            // color: ;
        }
        img{
            width: 100%;
            max-width: 150px;
        }
        table{
            td{
                font-size: 0.7rem;
            }
        }
        p.text-black{
            font-size: 0.9rem;
        }
        .my-orders-details table td{
            font-size: 14px;
        }
}
.My-Orders-page{
    h3{
        font-size: 1.2rem;
        font-weight: 600;
        @media(max-width:768px){
            font-size: 1.1rem !important;
            font-weight: 500;
        }
    }
}