.filter-btn {
  display: none;

  border: none;
  background: #eff9ff;

  @media (max-width: 991px) {
    display: flex;
    align-items: center;
    p{
      color: #428eeb;
      font-size: 16px;
      text-transform: capitalize;
      font-weight: 500;
      margin-left: 10px;
    }
    svg{
      width: 25px;
      height: 25px;
    }
  }
}
/* Sidebar.scss */

.sidebar {
  width: 270px;
  margin-top: 16px;

  @media (min-width: 789px) and (max-width: 991px) {
    margin-top: 15px;
    position: absolute;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 15px !important;
    position: absolute !important;
  }
  @media (min-width: 992px) and (max-width: 1060px) {
    width: 200px;
  }
  .collapse-group {

    button {
      display: block;
      width: 100%;
      background-color: $theme-bg;
      color: #fff;
      border: none;
      padding: 8px;
      border-radius: 0px;
      cursor: pointer;
      outline: none;
      text-align: left;
      font-size: 16px;
    }
    a{
      color: #505050;
      border-bottom:1px solid #e4e4e4;
    }
      input[type="checkbox"]{
        margin: 5px 10px;
        width:1.12rem;
        height:1.1rem;
      }
      label{
        color:#505050 ;
      }
    .collapse {
      ul {
        list-style: none !important;

        padding: 9px 14px;
        background-color: #e1fdff;
        display: flex;
        flex-direction: column;
        a {
          cursor: pointer;
          padding-top: 10px;
          &:hover {
            background-color: #d8d8d8;
            color: #0078d4;
          }

          input[type="checkbox"] {
            margin-right: 8px;
          }
        }
      }
      .collapsing{
        padding-top:0px !important;
        padding-bottom: 0px !important;
        margin: 0px !important;
      }

      &.show {
        left: 0%;
        transition: 0.3s ease;
        ul {
          list-style: none;

          a {
            color: #000;
            text-decoration: none;
          }
        }
      }
      &.hide {
        left: -100%;
        transition: 0.3s ease;
        ul {
          list-style: none;
        }
      }
    }
    .collapsing {
      ul {
        list-style: none;
        padding: 9px 14px;
      }
    }
  }

  .price-range {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
    div {
      margin: 0.5rem;
      display: flex;
      justify-content: space-evenly;
    }
    input {
      width: 90px;
      padding: 8px;
      margin-right: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      outline: none;
    }

    button {
      background-color: #007bff;
      color: #fff;
      border: none;
      text-align: center;
      padding: 8px;
      border-radius: 4px;
      cursor: pointer;
      outline: none;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
  &.show {
    
    .filterbar-wrapper {
      left: 0px;
      transition: 0.3s ease !important;
      display:block;
    }
  }

  .filterbar-wrapper {
    // background-color: $theme-bg;
    @media (max-width: 991px) {
      transition: 0.3s ease;
      position: relative;
      left: -1000px;
      top: 20px;
      background-color: white;
      display: none;
    }
  }
}

.sidebar-collapse-list {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  a {
    text-decoration: none;
    color: #000;
    margin: 5px 0;
  }
}
