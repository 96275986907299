// RegisterPage.scss
.background {
  background-image: url(../assets/images/Backdrop.png);
  padding: 40px 0px 60px 0px;
  width: 100%;
  min-height: 100%;
  background-size: cover;
  .register-page {
    background-color: $white;
    margin: 20px auto;
    width: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
@media(max-width:768px){
  width: 80%;
}
    h1 {
      font-size: 1.6rem;
      margin-bottom: 20px;
      font-weight: 600;
    }

    .row {
      margin-bottom: 15px;
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="password"] {
      padding: 10px;
      margin-right: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 13px;
      box-sizing: border-box;
      width: 100%;
      margin-bottom: 6px;
      font-weight: 500;

      &:focus {
        outline: none;
        border-color: #5e9ad6;
      }
    }
    .error {
      color: red;
    }
    .eye-icon {
      width: 20px;
      height: 20px;
      background-image:url("../assets/icons/eye-icon-show.svg"); 
      background-size: cover;
      position: absolute;
      left: 94%;
       top:43%; 
@media(max-width:768px){
  left: 84%;
       top:42%; 
}
      &.show {
        background-image: url("../assets/icons/eye-icon.svg");
      }
    }

    label {
      display: block;
      margin-bottom: 5px;
      text-align: left;
      font-size:15px;
.span1{
  color:red !important;
}
      input[type="checkbox"] {
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
      }
    }

    button {
      padding: 10px 20px;
      background-color: $theme-bg;
      border: none;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      cursor: pointer;
      width: 100%;
    }

    p {
      margin: 0;
      cursor: pointer;
      color: #6c757dd6;
    font-size: 15px;
    }
    .social-buttons {
      //   display: flex;
      //   justify-content: space-between;

      button {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        margin-bottom: 10px;
        cursor: pointer;
        background: white;
        border: 1px solid #333333;
        margin-bottom: 22px;
        color: #6c6c6c;
        font-weight: 600;
        img {
          width: 25px;
          margin-right: 10px;
        }
      }
    }
  }
}


// Guest SignInPage Scss Starts here
.GuestSignIn-page{
  &-wrapper{
    background-color: $white;
    margin: 50px 50px !important;
    padding: 80px 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    @media (max-width:768px){
      margin: 10px!important;
      padding: 20px 20px;
    }
    .background{
      background-image: none;
      padding: 0px;
      .Login-form {
          background-color: #fff;
          margin:auto 20px ;
          padding: 20px;
          border: 0;
          border-radius: 0px;
          box-shadow: none;
          @media (max-width:768px){
            margin: 10px!important;
            padding: 10px;
          }
      }
    }
    .guest-checkout-sec{
      padding: 20px 50px;
      @media (max-width:768px){
        margin: 10px!important;
        padding: 10px;
      }
      h1{
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 20px;
        @media (max-width:768px){
          font-size: 1.2rem;
          margin-bottom: 10px;
        }
      }
      p{
        font-size: 1rem;
        color: #6c6c6c;
        @media (max-width:768px){
          font-size: 0.9rem;
          margin-bottom: 10px;
        }
      }
    }
    .border-right{
      border-right: 1px solid #ebebeb;
    }
    button{
      background-color: $theme-bg;
      color: white;
      padding: 10px;
      &:hover{
        background-color: $theme-bg;
        color: white;
      }
      @media (max-width:768px){
        font-size: 0.9rem;
        margin-bottom: 10px;
      }
    }
  }
}