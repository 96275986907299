.footer {
  &-container {
    background: $theme-bg;
    color: $white;
    padding: 2rem 0;
    a {
      color: $white;
      text-decoration: none;
      font-family: 600;
      font-size: 11px;
      @media (min-width: 600px) {
        font-size: 14px;
      }
      div {
        font-weight: 500;
      }
    }
    .footer-text {
      text-decoration: underline;
    }
  }
  &-columns-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;
    padding: 0 1rem;
  }
  &-column {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    p {
      font-weight: 600;
    }
    &-about {
      max-width: 10rem;
      font-family: 600;
      font-size: 13px;
    }
    &-inner {
      display: flex;
      flex-direction: column;
      &-item {
        display: flex;
        img {
          width: 1rem;
          margin-right: 0.5rem;
        }
        margin-bottom: 0.5rem;
      }
    }
    &-social-media {
      display: flex;
      width: 80%;
      margin-top: 0.5rem;
      a{
        margin: 10px;
      }
    }
  }
  &-tag {
    font-size: 12px;
  }
}
