// Assuming you have a file named "Cart.scss" or you can include this within your main SCSS file.

.container {
  padding: 20px;
  .mainn-R {
    border: 1px solid #ddd;
    padding: 0px;
    margin: 0px;
    background-color: #ffff;
    padding: 7px 20px;
    font-size: 25px !important;
    border-radius: 8px;
    margin-bottom: 30px;
    margin-top: 20px;
    @media (max-width: 764px) {
      font-size: 1.2rem !important;
    }
    h2 {
      margin-bottom: 0 !important;
    }
  }

  .row {
    .button-RM {
      // margin-left:20%;
    }
    .remove-btn {
      background: #fff;
      padding: 3px 20px;
      border: 1px solid #ddd;
      margin-right: 20px;
      color: red;
      @media (max-width: 768px) {
        margin-right: 10px;
      }
    }
    .wishlist-btn {
      background: #fff;
      border: 1px solid #ddd;
      padding: 3px 30px;
      font-size: 15px;
      color: grey;
      @media (max-width: 768px) {
        padding: 5px;
      }
    }

    .quantity-content {
      border: 1px solid #80808047;
      border-radius: 2px;
    }
    .quantity-btn {
      border: 1px solid #ddd;
    }
    .quantity-btn1 {
      border: 1px solid #ddd;
    }
    .image-W {
      img {
        width: 100px;
        height: 100px;
        object-fit: contain;
        @media (max-width: 640px) {
          width: 70px;
          height: 70px;
        }
      }
    }

    .col {
      p {
        font-size: 14px;
        margin-bottom: 5px;
      }

      h2 {
        font-size: 15px !important;
        margin-bottom: 0px;
        text-transform: capitalize;
      }

      a {
        margin-bottom: 5px;
        text-decoration: underline;
      }

      .button {
        padding: 5px 10px;
        margin: 5px;
        border: 1px solid #ddd;
        background-color: #f5f5f5;
        cursor: pointer;

        &:hover {
          background-color: #e5e5e5;
        }
      }

      .d-flex {
        display: flex;
        align-items: center;

        button {
          margin: 0;
        }
      }
    }
  }

  .cart-sec-main {
    .main-R {
      border: 1px solid #ddd;
      padding: 30px 0px;
      margin-bottom: 20px;
      border-radius: 10px;
    }
    .main2-R {
      border: 1px solid #ddd;
      padding: 30px 0px;
    }
  }

  .cart-section {
    background-color: #ffff;
    padding: 22px;

    h3 {
      margin-bottom: 15px;
    }

    .form-select {
      width: 100%;
      margin-bottom: 15px;
      padding: 5px 35px 5px 10px;
      font-size: 14px;
      border: 1px solid #ddd;
    }

    .OrderSummary {
      display: flex;
      flex-direction: column;

      p {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
      }
    }

    button {
      width: 100%;
      padding: 10px;
      background-color: #2088b9;
      color: #fff;
      border: none;
      cursor: pointer;
      margin-top: 20px;
      border-radius: 8px;

      &:hover {
        background-color: #2088b9;
      }
    }
  }

  .back-btn {
    border: none;
    background: #2088b9;
    padding: 7px 20px;
    border-radius: 8px;
    color: #fff;
    margin-bottom: 30px;
    margin-top: 6px;
  }
}
