.guest-registered-adress {
  width: 90%;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 100%;
  }

  .checkout-address-button {
    background: #2088b9;
    border: none;
    padding: 7px 0px;
    color: white;
    border-radius: 8px;
    margin-top: 23px;
    width: 100%;
  }

  .user-content-sec {
    h2 {
      font-size: 18px !important;

      &h1 {
        font-weight: 500 !important;
      }
    }
  }
}

.CheckOutSteps {

  .guest-registered-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @media (min-width: 600px) {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    margin-bottom: 1rem;
    &-inner {
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      margin: 0.5rem;
      align-items: center;
      span {
        width: 2rem;
        height: 2rem;
        background: #2088b9;
        border-radius: 50%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5rem;
      }
    }
  }
}
