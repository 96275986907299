.container {
  .sorting-Align {
    background: white;
    padding: 0px 0px 8px 0px;
    border: 1px solid #D0D5DD;
    border-radius: 8px !important;
    margin: 30px 10px 20px 10px;

    @media (max-width:991px) {
      margin-top: 43px;
    }

    @media(max-width:768px) {
      margin-top: 50px;
      padding: 0px;
    }
  }

  .sort-font {
    font-size: 1.1rem !important;

    @media(max-width:768px) {
      font-size: 13px !important;
      line-height: 1.3;
    }
  }

  .sorting-dropdown2 {
    select {
      padding: 5px;
      font-size: 14px;
      border: 1px solid #ccc;
      border-radius: 5px;
      cursor: pointer;
      margin-left: 10px; // Add some space between the two dropdowns

      @media(max-width:768px) {
        padding: 4px;
        font-size: 11px;
      }
    }
  }

  .sorting-dropdown {
    select {
      padding: 2px 12px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
      outline: none;
      cursor: pointer;
      background-color: #fff;
      transition: border-color 0.2s ease-in-out;
      margin-top: 10px;

      @media(max-width:768px) {
        margin-top: 11px;
        padding: 3px 8px;
        font-size: 12px;

      }

      &:hover {
        border-color: #aaa;
      }

      &:focus {
        border-color: #4da6ff;
      }

      option {
        font-size: 16px;
      }
    }
  }

}