.sub-category-details{
    @media(max-width:764px){
        padding: 5px;
    }
    .filter-styling{
        border: 1px solid #6C6C6C;
    border-radius: 5px;
    background: white;
    padding: 3px 5px;
    margin-right: 10px;
    }
    button{
        border:none;
    }
}