.user-registered {
  .user-registered-adress {
    display: flex;
    flex-direction: row;
    justify-content: center;
    h2 {
      font-size: 16px !important;
      span {
        padding: 5px 11px;
        background: #2088b9;
        border-radius: 50%;
        color: #fff;
      }
    }
  }

  .user-registered-section {
    .address-1 {
      border: 1px solid #d0d5dd;
      padding: 22px 20px;
    }
    input[type="radio"]#firstname {
      accent-color: #0078d4;
    }
    .radio-1 {
      width: 20px;
      margin-right: 10px;
    }
    .right-arrow{
      padding-bottom: 5px;
      
    }

    p {
      line-height: 0.6 !important;
      font-size: 14px;
      @media(max-width:768px)
      {
        line-height: 1.1 !important;
      }
    }
    button {
      background: #2088b9;
      font-size: 15px;
      padding: 5px 14px;
      font-weight: 500;
      border: none;
    }
    .shipping-address {
      margin-bottom: -0.5em;
    }
    .blling-address{
      font-size: 18px !important;
      font-weight: 600 !important;
    }
    .address-button{
width:100%;margin-top:25px;
color:white;
padding:8px 0px;
border-radius: 8px;
    }
    .adress-buttons {
      button {
        border: 1px solid #ddd;
        background-color: #eff9ff;
        padding: 5px 10px;
        border-radius: 5px;
      }
    }
  }
  .modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;

    &.show {
      display: block;
    }

    .modal-dialog {
      margin: 10vh auto;
      max-width: 80%;
      background-color: #fff;
      border-radius: 5px;

      .modal-content {
        padding: 20px;

        .modal-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;

          h5.modal-title {
            margin: 0;
          }

          button.close {
            background: none;
            border: none;
            cursor: pointer;
            padding: 0;
          }
        }

        .modal-body {
          padding: 20px;

          label {
            font-weight: bold;
          }

          input {
            width: 100%;
            padding: 8px;
            margin-bottom: 15px;
            border: 1px solid #ccc;
            border-radius: 4px;
          }

          /* Additional styling for input fields can be added here */
        }

        .modal-footer {
          display: flex;
          justify-content: flex-end;
          padding: 10px;

          button {
            padding: 8px 20px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
          }

          .btn-secondary {
            background-color: #ccc;
            margin-right: 10px;
          }

          .btn-primary {
            background-color: #007bff;
            color: #fff;
          }
        }
      }
    }
  }
}
