.pagination-container {
  display: flex;
  justify-content: end;
  margin:10px 50px;
  list-style-type: none;
  @media(max-width:640px){
    margin: 0px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 5;
    line-height: 1.43;
    font-size: 14px;
    min-width: 32px;
    @media(max-width:640px){
      min-width: auto;
    }
    &.numbers{
      &:hover {
        background-color: rgba(16, 16, 16, 0.04);
        cursor: pointer;
      }
    }

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    

    &.selected {
      background-color: rgba(0, 0, 0, 0.261);
      
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: '';
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.5em;
        height: 0.5em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
.arrow-box{
  border-radius: 0.5rem;
  border: 1px solid var(--gray-300, #D0D5DD);
  background: var(--white, #FFF);
  padding: 8px 15px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  &:hover {
    background-color: rgba(16, 16, 16, 0.04);
    cursor: pointer;
  }
}
