.home-wrapper{
h2{
  font-size: 2rem !important;
  @media (max-width: 768px) {
    font-size: 1.6rem !important;
  }
}
  .banner-swiper {
    height: 700px;
  // height: 70% !important;
  width: 100vw;
  @media (max-width:1100px) {
    height:550px;
  }
  @media (max-width:992px) {
    height:500px;
  }
  @media (max-width: 768px) {
    height:300px;
  }
  @media (max-width: 576px) {
    height:180px;
  }
  &-slide {
    height: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .swiper-pagination-bullet {
    &-active {
      width: 20px;
      height: 8px;
      border-radius: 3px;
    }
  }
}

// Top Categories Stylling
.topcategories {
  &-swiper {
    &-slide {
      &-inner {
        width: 100%;
        height: 200px;
        border-radius: 5px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 900px) {
          width: auto;
        }
      }
    }
  }
}

.home-grid {
  @media (max-width: 900px) {
    height: auto;
  }
  &-poster {
    overflow: hidden;
    img {
      width: 100%;
      // height: 100%;
      object-fit: cover;
      @media (max-width: 900px) {
        // height: auto;
        margin: 10px auto !important;
      }
    }
  }
  &-right {
    .top{
      height: 100%;
      max-height: 250px;
    }
    &-sub {
      display: grid;
      grid-template-rows: 1fr 1fr;
      @media (max-width: 900px) {
        grid-template-rows: auto;
      }
    }
  }
}

.callToAction {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(../assets/images/calltoAction.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 10px;
  @media (max-width: 900px) {
    padding: 50px 10px;
  }
  &-inner {
    background: rgba(26, 26, 26, 0.55);
    backdrop-filter: blur(10px);
    color: white;
    border: 3px solid white;
    h2 {
      font-family: $font-title;
    }
  }
  .btn{
    background-color: $theme-bg;
    &:hover{
      background-color: $theme-bg;
    }
  }
}

}