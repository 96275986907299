// User Profile
.container {
  .tab-buttons {
    display: flex;

    button {
      background: none;
      border: none;
      text-transform: capitalize !important;
      cursor: pointer;
      font-size: 17px;
      padding: 0;
      margin-right: 40px;
      &.active {
        text-decoration: underline;
        font-weight: 500;
        color: #2088b9;
      }

      @media (max-width: 768px) {
        font-size: 14px;
        margin-right: 11px;
      }
    }
  }
  .btn-cancel {
    width: 100%;
    border: 1px solid #80808070;
    background: white;
    padding: 6px;
    border-radius: 8px;
  }
  .btn-save {
    width: 100%;
    background: #2088b9;
    padding: 6px;
    border: none;
    border-radius: 8px;
    color: white;
  }
}

//   personal-information
.personal-information {
  .error {
    color: red;
  }
}

// Password

/* Passwords.scss */

.password {
  .input-group {
    position: relative;

    input[type="password"] {
      padding-right: 40px; /* Adjust the padding based on your button/icon size */
    }

    .btn-toggle {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      background: none;
      border: none;
      cursor: pointer;

      img {
        width: 20px;
        height: auto;
      }
    }
  }

  .error {
    color: red;
    font-size: 14px;
  }
}

//address component
.address-label {
  padding: 1rem;
  border: 1px solid gray;
  border-radius: 5px;
  margin-top: 1rem;
  &-title {
    margin-left: 0.3rem;
    font-weight: 600;
    margin-bottom: 0;
  }
  &-container {
    margin-top: 1.5rem;
    width: 70%;
    @media(max-width:996px){
      width: auto;
    }
  }
  &-button {
    color: #fff;
    background: $theme-bg;
    border: none;
    border-radius: 5px;
    padding: 0.3rem 0.5rem;
    text-transform: capitalize !important;
    @media(max-width:996px){
     font-size: 12px;
     width: auto;
    }
    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.address-button {
  margin-right: 1rem !important;
  background: transparent !important;
  font-size: 14px;
  text-transform: capitalize !important;
  border-radius: 5px;
  width:100% !important;
  background: #2088b9  !important;
  padding: 7px 0px;
  color: white !important;
  border: none;
  padding: 7px 20px !important;
  &-container {
    display: flex;
    margin-top: 1rem !important;
  }
}
