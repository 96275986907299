@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");


//reference the variables with styles

$theme-bg: #2088B9;
$white: #fff;

//media query breakpoints references
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$font-title: "Montserrat", sans-serif;
$font-body: "Poppins", sans-serif;