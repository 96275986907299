// Contact.scss

// Base styles for container and row

.banner {
  background-color: #eff9ff;
  margin: 0;
  padding: 0;

  .contact-content {
    margin-top: 18%;
    padding-left: 15%;
    padding-right: 10%;
    .contact-h3 {
      font-size: 1.5rem !important;
      color: #252b42;
    }
    .contact-h2 {
      font-size: 2rem !important;
      color: #252b42;
      @media (max-width: 768px) {
        font-size: 2.5rem !important;
        padding-bottom: 15px;
      }
    }
    .contact-p {
      font-size: 1rem !important;
    }
    .social-icons {
      display: flex;
      img {
        width: 1.3rem !important;
        margin-right: 19px;
      }
    }
  }
}

.banner-image {
  background-image: url(../assets/images/banner.png);
  height: 600px;
  width: 100%;
  object-fit: contain;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    width: 100%;
    height: 500px;
    background-position: center;
    background-size: contain;
  }
}

.row {
  margin-bottom: 20px;
}

.boxex-contact {
  background-color: white;
  padding: 40px 20px;

  &:hover {
    background-color: #2088b9;
    color: white;
  }

  img {
    height: 37px;
    width: 35px;
    color: red !important;
  }

  &:hover {
    color: #ccc;
  }
}

// Styles for "Visit Our Office" section
.contact-content {
  h3 {
    font-size: 1.2rem !important;
    color: #1a1a1a;
    padding-top: 3%;
  }

  h2 {
    font-size: 1.9rem !important;
    color: #1a1a1a;
    font-weight: 500;
    padding-bottom: 40px;
  }
}

.col-lg-3 {
  margin-bottom: 10px;

  p {
    margin: 5px 0;
  }
}
.contactus-section-map {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #eff9ff;
  .col-lg-6 {
    position: relative;

    .map-container {
      position: relative;
      width: 100%;
      height: 300px;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    iframe {
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  // Styles for "Contact Us Form" section
  h3 {
    font-size: 24px;
    font-weight: bold;
  }

  h2 {
    font-size: 18px;
  }

  .form-group {
    margin-bottom: 15px;

    label {
      font-weight: bold;
      font-size: 14px;
    }
    .astrix-s {
      color: red;
    }

    input,
    textarea {
      width: 100%;
      padding: 8px;
      font-size: 14px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }
}
// Media queries for responsiveness
@media (max-width: 992px) {
  .col-lg-6 {
    .map-container {
      height: 200px;
    }
  }
}

@media (max-width: 768px) {
  .col-lg-4,
  .col-lg-6 {
    margin-bottom: 20px;
  }
}
