.bac-otp{
    background-image: url(../assets/images/Backdrop.png);
    padding:40px 0px 60px 0px;
    width:100%;
    min-height:100%;
    background-size: cover;
.otp-container {
    text-align: start;
   max-width: 600px;
    background-color: white;
    min-height: 430px;
   border-radius: 10px;
    padding:5% 5%;
    margin: 40px auto;
  }
  
  .otp-input-container {
    
  }
  
  input {
    width:100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
  }
  p{
    cursor: pointer;
  }
  
  .timer {
    position: absolute;
    right: 10px;
    font-size: 12px;
    color: #888;
  }
  
  .resend-button {
    padding: 10px;
    border: none;
    background-color: transparent;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .verify-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #2088B9;
    color: #fff;
    border: none;
    border-radius: 4px;
    width:100%;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .verify-button:hover {
    background-color: #2088B9;
    color:white;
  }
}
