.container {
  padding: 0 5%;
  max-width: 90%;
  margin: 0 auto;
}

.title {
  font-size: 1rem;
  font-weight: 600;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-title;
}

body,
p,
a,
div {
  font-family: $font-body;
}
body {
  background-color: rgba(239, 249, 255, 1) !important;
}

button {
  text-transform: capitalize !important;
}
.btn {
  text-transform: capitalize !important;
}

.swiper{
  z-index: 0 !important;
}