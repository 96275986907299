/* @import url("https://cdn.jsdelivr.net/npm/pixeden-stroke-7-icon@1.2.3/pe-icon-7-stroke/dist/pe-icon-7-stroke.min.css"); */
body {
  margin-top: 20px;
}

.MyOrder-View {
  .text-warranty {
    color: #FF5E00;
  }

  .header {
    img {
      width: 25px;
      height: 25px;
      object-fit: contain;
    }

    button {
      background-color: $theme-bg;
      color: white;
    }
  }

  .steps .step {
    display: block;
    width: 100%;
    margin-bottom: 35px;
    text-align: center
  }

  .steps .step .step-icon-wrap {
    display: block;
    position: relative;
    width: 100%;
    height: 20px;
    text-align: center
  }

  .steps .step .step-icon-wrap::before,
  .steps .step .step-icon-wrap::after {
    display: block;
    position: absolute;
    top: 50%;
    width: 50%;
    height: 3px;
    margin-top: -1px;
    background-color: #e1e7ec;
    content: '';
    z-index: 1
  }

  .steps .step .step-icon-wrap::before {
    left: 0
  }

  .steps .step .step-icon-wrap::after {
    right: 0
  }

  .steps .step .step-icon {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    border: 1px solid #e1e7ec;
    border-radius: 50%;
    background-color: #f5f5f5;
    color: #374250;
    font-size: 38px;
    line-height: 81px;
    z-index: 5
  }

  .steps .step .step-title {
    margin-top: 16px;
    margin-bottom: 0;
    color: #606975;
    font-size: 14px;
    font-weight: 500
  }

  .steps .step:first-child .step-icon-wrap::before {
    display: none
  }

  .steps .step:last-child .step-icon-wrap::after {
    display: none
  }

  .steps .step.completed .step-icon-wrap::before,
  .steps .step.completed .step-icon-wrap::after {
    background-color: #0da9ef
  }

  .steps .step.completed .step-icon {
    border-color: #0da9ef;
    background-color: #0da9ef;
    color: #fff
  }

  @media (max-width: 576px) {

    .flex-sm-nowrap .step .step-icon-wrap::before,
    .flex-sm-nowrap .step .step-icon-wrap::after {
      display: none
    }
  }

  @media (max-width: 768px) {

    .flex-md-nowrap .step .step-icon-wrap::before,
    .flex-md-nowrap .step .step-icon-wrap::after {
      display: none
    }
  }

  @media (max-width: 991px) {

    .flex-lg-nowrap .step .step-icon-wrap::before,
    .flex-lg-nowrap .step .step-icon-wrap::after {
      display: none
    }
  }

  @media (max-width: 1200px) {

    .flex-xl-nowrap .step .step-icon-wrap::before,
    .flex-xl-nowrap .step .step-icon-wrap::after {
      display: none
    }
  }

  .bg-faded,
  .bg-secondary {
    background-color: #f5f5f5 !important;
  }

  .My-orders-wrapper {
    margin: 0px;
  }

  .order-items {
    .details {
      &-table {
        td {
          p {
            font-size: 0.9rem;
            font-weight: 400;
          }
        }
      }
    }

    margin-top:50px;

    .item-row {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      padding: 20px;
    }
  }

  h2 {
    margin-bottom: 10px;
    text-align: left;
    font-size: 20px !important;
    color: #1A1A1A;
    padding-bottom: 0;
  }

  p {
    color: #6C6C6C;
    font-size: 16px;
    line-height: 1.2;
  }

  .order-info {
    // Add order info styles if needed

    p {
      // Add order info paragraph styles if needed
    }

    .form-group {

      label {
        font-weight: normal;
        color: #6C6C6C;
      }

      select {
        width: 200px;
        padding: 3px 0px;
        margin-left: 5px;
        margin-top: -3px;
      }
    }
  }


  .order-info,
  .address-info {
    margin-bottom: 20px;

    p {
      span {
        color: green;
      }
    }
  }

  .cart-total {
    margin-top: 20px;

    h2 {
      margin-bottom: 10px;
      background-color: #d7d7d7;
      padding: 10px 40px;

    }

    p {
      color: #6C6C6C;
    }
  }

  .total-info {
    margin-bottom: 20px;

    p {}
  }

  @media (max-width: 768px) {
    .item-row {
      display: block !important;
    }
  }

}