.product-category-details {
    .filter-styling {
        border: 1px solid #6C6C6C;
        border-radius: 5px;
        background: white;
        padding: 3px 5px;
        margin-right: 10px;

        .cancel-btn {
            border: none;
            background: transparent;
        }
    }

    .clear-btn {
        border: none;
    }
}