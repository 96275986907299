.Product-Details-wrapper {
  .product-details {
    padding: 20px;
    @media (max-width: 991px) {
      padding: 10px;
    }
    h1 {
      font-size: 1.5rem;
    }
    .badge {
      background-color: #f47e11;
      @media (max-width: 991px) {
        font-size: 12px;
      }
    }
    .selling-price {
      span {
        font-size: 18px;
        color: #f47e11;
        padding: 0 !important;
        @media (max-width: 991px) {
          font-size: 14px;
        }
      }
    }
    select {
      padding: 6px;
      font-size: 14px;
      font-weight: 600;
      color: #f47e11;
      background: transparent;
      border-radius: 10px;
    }
    .availability {
      font-size: 1.25rem;
      font-weight: 700;
      @media (max-width: 991px) {
        font-size: 1rem;
      }
    }
    .brand-name {
      color: rgb(94, 94, 94);
      font-weight: 500;
      @media (max-width: 991px) {
        font-size: 0.9rem;
      }
    }
    h5 {
      @media (max-width: 991px) {
        font-size: 1rem;
        font-weight: 600;
      }
    }
    .addTo {
      &-Cart-btn {
        background-color: $theme-bg;
        color: white;
        width: 320px;
        transition: 0.3s;
        border-radius: 2px;
        padding: 8px 10px;
        font-size: 18px;
        font-weight: 500;
        &:hover {
          background-color: rgb(14, 116, 164);
          color: rgb(255, 255, 255);
        }
        @media (max-width: 991px) {
          font-size: 1rem;
          font-weight: 500;
          width: 250px;
          padding: 5px 10px;
        }
      }
      &-wishlist-btn {
        background-color: white;
        color: rgb(69, 69, 69);
        width: 320px;
        transition: 0.3s;
        border-radius: 2px;
        padding: 8px 10px;
        font-size: 18px;
        font-weight: 400;
        border: 1.5px solid rgb(135, 135, 135);
        &:hover {
          border: 1.5px solid rgb(135, 135, 135);
        }
        @media (max-width: 991px) {
          font-size: 1rem;
          font-weight: 500;
          width: 250px;
          padding: 5px 10px;
        }
      }
    }
    .input-group {
      width: 150px;
      @media (max-width: 991px) {
        width: 120px;
      }
      .input-group-text,
      .form-control {
        @media (max-width: 991px) {
          font-size: 12px !important;
        }
      }
    }
  }
  .ProductDescription {
    &-head {
      border-bottom: 2px solid rgba(13, 110, 253, 1);
      color: rgba(13, 110, 253, 1);
      line-height: 1.4;
      font-weight: 600;
      @media (max-width: 991px) {
        font-size: 18px;
      }
    }
    hr {
      border-color: rgb(99, 99, 99);
      border-width: 1.5px;
    }
    &-para {
      color: rgb(94, 94, 94) !important;
      @media (max-width: 991px) {
        font-size: 0.9rem;
      }
    }
    .points {
      font-size: 1rem;
      color: rgb(94, 94, 94);
      margin: 1rem auto;
      @media (max-width: 991px) {
        font-size: 0.9rem;
        margin: 0.5rem auto;
      }
      img {
        width: 22px;
        height: 22px;
        margin: 5px;
        @media (max-width: 991px) {
          width: 18px;
          height: 18px;
          margin: 3px;
        }
      }
    }
    table {
      border-color: rgb(169, 169, 169);
      margin: 1rem 0px;

      @media (max-width: 991px) {
        margin: 0.5rem auto;
      }
      td {
        padding: 8px 8rem 8px 8px;
        @media (max-width: 991px) {
          font-size: 14px;
          padding: 8px 5rem 8px 8px;
        }
        @media (max-width: 778px) {
          padding: 8px 1rem 8px 8px;
        }
      }
    }
    .specifications-heading {
      background-color: rgb(206, 239, 255);
    }
  }
  .rating-number {
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 0px !important;
  }

  .star-rating {
    display: inline-block;

    .star-icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("../assets/icons/goldstar.svg") no-repeat;
      background-size: cover;
      margin-right: 0.2rem;

      &.filled {
        background: url("../assets/icons/star1.svg") no-repeat;
        background-size: cover;
        width: 13px;
        height: 13px;
      }
    }
  }

  .rating-section {
    .btn-reviewandrating {
      border: none;
      background: #2088b9;
      color: white;
      padding: 6px 25px;
      border-radius: 3px;
      margin-top: 19px;
      font-size: 14px;
    }
  }
  .rating-progress {
    .progress {
      height: 10px;
      margin-bottom: 0.25rem;
      width: 50%;
      margin-top: 6px;
      margin-left: 10px;
      margin-right: 10px;
    }

    p {
      font-size: 0.875rem;
      color: #888;
    }
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider {
        background-color: #2196f3;
      }

      &:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
      }

      &:checked + .slider:before {
        transform: translateX(20px);
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: 0.4s;
      border-radius: 20px;

      &:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
      }
    }

    .slider.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }
  }
  .customer-review {
    background: white;
    padding: 20px;
    .rating-number {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .rating-number {
      font-size: 14px;
      margin-top: 3px;
      color: white;
    }
    .review-right {
      margin-left: 20%;
    }
    .review-s {
      background: #038d63;
      padding: 0px 0.6rem;
      border-radius: 8px;
      display: flex;
      align-items: center;
    }
    .star-rating {
      display: inline-block;

      .star-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url("../assets/icons/star1.svg") no-repeat;
        background-size: cover;
        margin-right: 0.2rem;

        &.filled {
          background: url("../assets/icons/star.svg") no-repeat;
          background-size: cover;
          width: 12px;
          height: 12px;
        }
      }
    }

    .customer-name {
      font-weight: bold;
      margin-left: 0.5rem;
    }

    .review-date {
      color: #888;
      margin-left: 0.5rem;
    }

    .customer-review-text {
      font-size: 1rem;
      margin-top: 0.5rem;
    }
  }
  /* Popup styles */
  .popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    justify-content: center;
    align-items: center;
  }

  .popup.active {
    display: flex;
  }

  .popup-content {
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
    max-width: 600px;
    width: 100%;
    text-align: start;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    .popupstar {
      img {
        width: 17px;
        margin-right: 10px;
        margin-bottom: 24px;
      }
    }
  }

  .popup-header {
    font-weight: bold;
    margin-bottom: 10px;
    .popupstar button.selected {
      color: gold; 
    }
    .popuptext {
      font-size: 16px !important;
      padding-bottom: 4px;
    }
    .ratingtext {
      font-size: 16px !important;
    }
  }

  .popup-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .popup-buttons {
    display: flex;
    gap: 10px;
  }

  .popup-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .popup-button.cancel {
    background-color: #fff;
    width: 45%;
    border: 1px solid #ddd;
    height: 42px;
  }

  .popup-button.submit {
    background-color: #0078d4;
    color: #fff;
    width: 45%;
    height: 42px;
  }

  .popup-button:hover {
    background-color: darken(#0078d4, 10%);
    color: white;
  }
}

.Product-image-gallery {
  margin: 20px;
  &-thumb {
    @media (max-width: 768px) {
      margin-top: 15px;
    }
    img {
      width: 80px;
      height: 80px;
      margin: 10px;
      @media (max-width: 768px) {
        height: 47px;
        width: 47px;
      }
    }
  }
  &-Main {
    img {
      width: 500px;
      // height: 400px;
     
      object-fit: contain;
      @media (max-width: 768px) {
        height: 300px;
        width: 300px;
      }
    }
  }
}

.flex-sm-reverse {
  @media (max-width: 768px) {
    flex-direction: column-reverse !important;
  }
}
