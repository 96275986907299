.Order-Status-Success {
    .btn {
        background-color: $theme-bg;
        color: white;
        font-weight: 500;
        margin: 30px auto;

        &:hover {
            background-color: $theme-bg;
            color: white;
        }
    }

    .Order-details {
        display: flex;
        flex-direction: column;
        @media (min-width:768px) {
            flex-direction: row;
        }
        border: 1.5px solid rgb(196, 196, 196);
        &-box {
            border-right: 1.5px solid rgb(196, 196, 196);
        }
    }

    &-image {

        img {
            width: 200px;
            height: 200px;
            object-fit: contain;

            @media(max-width:764px) {
                width: 120px;
                height: 120px;
            }
        }
    }
}