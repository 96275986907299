.product-card-wrapper {
  @media (max-width: 764px) {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
}
.Product-Card {
  background-color: white;
  transition: 0.4s;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 18rem;
  @media (min-width: 1200px) {
    min-height: 25rem;
  }
  
  .badge {
    @media (max-width: 764px) {
      font-size: 10px;
    }
  }
  &-price {
    @media (max-width: 764px) {
      font-size: 13px;
    }
    &-gst {
      color: #ef7e15;
      margin-left: 0.5rem;
    }
  }
  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  @media (max-width: 768px) {
    // width: 180px;
  }
  .add-to-cart-btn {
    background-color: $theme-bg;
    font-size: 18px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  &-link {
    text-overflow: ellipsis;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 1;
    }
  }
  &-SellingPrice {
    font-size: 20px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  strike {
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  &-subhead {
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-rating {
      span {
        font-size: 1.2rem;
      }
    }
  }
  &-image {
    width: 100%;
    height: 200px;
    @media (max-width: 768px) {
      height: 100px;
    }

    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .wishlist {
    img {
      width: 20px;
      cursor: pointer;
    }
  }
}

.cart-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px !important;
  background-color: #fff;
  color: black;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.cart-message {
  font-size: 16px;
  margin-bottom: 10px;
}

.close-cart-popup {
  background-color: #2088b9;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: darken(#2088b9, 10%);
  }
}

.heart {
  cursor: pointer;
  font-size: 1.6rem !important;
}
