
.container{
    margin: 0 auto;
    max-width: 1000px !important;
    padding: 20px;
.termsofuse{
    padding: 20px;
    .termsofuse-text{
        
        font-size: 22px !important;
        font-weight: 600;
        padding-top:10px !important
    }
    .termsofuse-para{
        font-size: 16px;
        margin-bottom: 10px;
        line-height: 1.8;
        margin-bottom:20px;
    }
}
}