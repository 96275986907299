.password-container {
  background-image: url(../assets/images/Backdrop.png);
  padding: 40px 0px 60px 0px;
  min-height: 100%;
  background-size: cover !important;
  background-position: center !important;

  .reset-container {
    width: 40%;
    margin: 40px auto;
    border-radius: 13px;
    @media (max-width: 768px) {
      width: 80%;
    }
    .reset-password-form {
      display: flex;
      flex-direction: column;
      background-color: #f5f5f5;
      padding: 20px 25px 52px 25px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .form-heading {
        font-size: 24px;
        margin-bottom: 20px;
        text-align: start !important;
      }

      .form-group {
        position: relative;
        width: 100%;
        margin-bottom: 20px;

        .password-input-container {
          position: relative;

          .password-toggle-button {
            background: none;
            position: absolute;
            right: 5%;
            top: 28%;
            border: none;
            padding: 0;
            margin: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;

            svg {
              width: 20px;
              height: 20px;

              transition: fill 0.2s ease-in-out;
            }
          }
        }
        .password-toggle-button:hover {
          svg {
            /* Icon color on hover */
          }
        }

        label {
          font-weight: bold;
        }

        input {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 8px;
          transition: border-color 0.2s ease-in-out;
        }

        .password-toggle {
          position: absolute;
          top: 66%;
          right: 8px;
          font-size: 14px;
          cursor: pointer;
          transform: translateY(-50%);
        }
      }

      .error-msg {
        color: red;
        margin-top: -10px;
        margin-bottom: 10px;
      }

      button {
        padding: 10px;
        background-color: #2088b9;
        color: #fff;
        border: none;
        border-radius: 8px;
        cursor: pointer;

        &:hover {
          background-color: #2088b9;
        }
      }

      @media screen and (max-width: 600px) {
        .form-heading {
          font-size: 24px;
        }
      }
    }
  }
}
