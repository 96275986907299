.container {
  margin: 0 auto;
  max-width: 800px;
  padding: 20px;
  .privacy-policy {
    padding: 20px;

    .pricacy-policy-main {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
    }

    .privacy-policy-para-main {
      font-size: 16px;
      line-height: 1.8;
      margin-bottom: 20px;
      font-weight: 600;
    }

    .privacy-policy-text {
      padding-bottom: 8px;
      font-size: 24px !important;
      font-weight: 600;
    }

    .privacy-policy-para {
      font-size: 16px;
      margin-bottom: 10px;
      line-height: 1.8;
    }

    ul {
      list-style-type: disc;
      margin-left: 20px;
    }

    li {
      font-size: 16px;
      margin-bottom: 5px;
      line-height: 1.7;
    }
  }
}
