.forgot-background{
  background-image: url(../assets/images/Backdrop.png);
  padding: 40px 0px 60px 0px;
  min-height: 100%;
  background-size: cover !important;
  background-position: center !important;
.forgot-password-otp-page {
  width: 40%;
  padding: 30px 25px 45px 25px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 50px auto;
  margin-top: 50px;
  background-color: $white;
  @media(max-width:768px){
    width: 80%;
  }
  .forgot-text {
      margin-top: 20px;
      font-size: 1.6rem; 
      text-align: start!important;
      font-weight: 600;
    }
  
    p {
      margin-top: 10px;
      font-size: 14px;
      color:#64748B;
      font-weight: 500;
    }
  
    .email-input {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
     
  
      label {
        font-size: 16px;
      }
  
      input {
        width: 100%;
        height: 30px;
        margin-top: 5px;
        padding: 17px 5px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
    }
  
    button {
      margin-top: 20px;
      padding: 7px 20px;
      background-color: #2088B9;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      width:100%;
      font-size: 16px;
  
      &:hover {
        background-color: #2088B9;
      }
    }
  }
}
  